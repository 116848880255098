export enum Gateways {
    Mock = "Mock",
    AlfaBank = "AlfaBank",
    PastaBank = "PastaBank",
    Rietumu = "Rietumu",
    TCS = "TCS",
    AlfaBankKz = "AlfaBankKz",
    Rncb = "Rncb",
    SberbankKz = "SberbankKz",
    MdmBank = "MdmBank",
    TCSPayout = "TCSPayout",
    TCSAFT = "TCSAFT", 
    Sberbank = "Sberbank", 
    PayDoo = "PayDoo",
    PayDooPayout = "PayDooPayout",
    BelGazPromBank = "BelGazPromBank", 
    BelVeb = "BelVeb", 
    eWayHK = "eWayHK", 
    Moneta = "Moneta", 
    WireCard = "WireCard", 
    RSB = "RSB", 
    GlobalPayments = "GlobalPayments",
    PayClub = "PayClub", 
    Upc = "Upc", 
    WinPay = "WinPay", 
    WinPayOut = "WinPayOut", 
    SberBankRbsRu = "SberBankRbsRu", 
    KuveitTurk = "KuveitTurk", 
    MonetaPayOut = "MonetaPayOut", 
    MonetaV2 = "MonetaV2", 
    AlfaBankRbsKz = "AlfaBankRbsKz", 
    TcsMapi = "TcsMapi", 
    TcsMapiPayout = "TcsMapiPayout", 
    AlfaBankRbsBy = "AlfaBankRbsBy", 
    BePaid = "BePaid", 
    VTB = "VTB", 
    PayGateway = "PayGateway", 
    Connectum = "Connectum", 
    ConnectumPayOut = "ConnectumPayOut", 
    MtsBankRbsRu = "MtsBankRbsRu", 
    Ziraat = "Ziraat", 
    UpcPayout = "UpcPayout", 
    Kicb = "Kicb", 
    IdramBankRbs = "IdramBankRbs", 
    Paysage = "Paysage",
    NetworkAe = "NetworkAe", 
    Mkb = "Mkb", 
    Oschad = "Oschad",
    Jysan = "Jysan",
    Kapital = "Kapital",
    Bcc = "Bcc"
}