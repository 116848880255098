import { BaseResponse } from './contracts/BaseResponse';
import { environment } from "../environment";
import { Utils, createLoadingOverlay } from "../util";
import { SetBrowserInfoResponse } from './contracts/SetBrowserInfoResponse';
import { ThreeDsAction } from './contracts/ThreeDsAction';
import { Gateways } from './contracts/Gateways';
import 'core-js/features/string/replace-all'

export class Main {

  private CurrentAction: ThreeDsAction;
  private Params: URLSearchParams;
  private acsFrame: HTMLIFrameElement | undefined;
  private acsDataPacketFrame: HTMLIFrameElement | undefined;

  constructor() {
    createLoadingOverlay(document);
    this.Params = new URLSearchParams(window.location.search);

    this.CurrentAction = parseInt(this.Params.get("ThreeDsAction")!, 10)

    switch (this.CurrentAction) {
      case ThreeDsAction.BrowserInfo:
        this.initializePayment();
        break;
      case ThreeDsAction.MerchantTermUrl:
        this.finalizeChallengeTransaction();
        break;
      case ThreeDsAction.SberKzChallenge:
        this.SberKzChallenge();
        break;
      case ThreeDsAction.JysanShortChallenge:
      case ThreeDsAction.Way4ChallengeWithout3dsMethod:
        this.Way4ChallengeWithout3dsMethod();
        break;
      case ThreeDsAction.ConnectumChallenge:
        this.ConnectumChallenge();
        break;
      case ThreeDsAction.UnlimintChallenge:
        this.UnlimintChallenge();
        break;
      default:
        throw (new Error(`${this.CurrentAction} not implemented`))
    }
  }

  private UnlimintChallenge()
  {
    const acsUrl = this.Params.get("AcsUrl");
    Utils.createAndSubmitForm(window.document, acsUrl!, "");
  }


  private ConnectumChallenge() {

    const acsUrl = this.Params.get("AcsUrl");
    const threeDsSessionData = this.Params.get("ThreeDsSessionData");
    const creq =  this.Params.get("CReq");
    const threeDsVersion = this.Params.get("ThreeDsVersion");
    const merchantTermUrl = this.Params.get("MerchantTermUrl");
    const mD = this.Params.get("TransactionId");

    var tdsData = null;

    if(threeDsVersion?.startsWith("1"))
    {
        tdsData = {
          PaReq: creq,
          MD: mD,
          TermUrl: merchantTermUrl
        }
    }

    tdsData = {
      creq: Utils.removePadding(creq),
      threeDSSessionData: threeDsSessionData
    }

    Utils.createAndSubmitForm(window.document, acsUrl!, tdsData);
  }

  private Way4ChallengeWithout3dsMethod()
  {
    const acsUrl = this.Params.get("AcsUrl");
    const threeDsSessionData = this.Params.get("ThreeDsSessionData");
    const creq =  this.Params.get("CReq");
    const threeDsVersion = this.Params.get("ThreeDsVersion");
    const merchantTermUrl = this.Params.get("MerchantTermUrl");
    const mD = this.Params.get("TransactionId");

    var tdsData = null;

    if(threeDsVersion?.startsWith("1"))
    {
      tdsData = {
        PaReq: creq,
        MD: mD,
        TermUrl: merchantTermUrl
      };
    }
    else
    {
        if(threeDsSessionData)
        {
          tdsData = {
            creq: Utils.removePadding(creq),
            threeDSSessionData: threeDsSessionData
          };
        }
        else
        {
          tdsData = {
            creq: Utils.removePadding(creq)
          };
        }
    }

    Utils.createAndSubmitForm(window.document, acsUrl!, tdsData);
  }

  private SberKzChallenge()
  {
    const acsUrl = this.Params.get("AcsUrl");
    const threeDsSessionData = this.Params.get("ThreeDsSessionData");
    const creq =  this.Params.get("CReq");
    const threeDsVersion = this.Params.get("ThreeDsVersion");
    const merchantTermUrl = this.Params.get("MerchantTermUrl");
    const mD = this.Params.get("TransactionId");

    var tdsData = threeDsVersion?.startsWith("1") ?
          {
            PaReq: creq,
            MD: mD,
            TermUrl: merchantTermUrl
          } :
          {
            creq: Utils.removePadding(creq),
            threeDSSessionData: threeDsSessionData
          }

    console.log("tdsData: " + acsUrl + JSON.stringify(tdsData));

    Utils.createAndSubmitForm(window.document, acsUrl!, tdsData);
  }

  private initializePayment() {
    const transactionId = parseInt(this.Params.get("TransactionId")!, 10);
    const threeDsMethodUrl = this.Params.get("ThreeDsMethodUrl");
    const threeDsServerTransId = this.Params.get("ThreeDsServerTransId");
    const threeDsCallbackId = this.Params.get("ThreeDsCallBackId");
    const threeDsMethodUrlServer = this.Params.get("ThreeDsMethodUrlServer");
    const threeDsMethodDataPacked = this.Params.get("ThreeDsMethodDataPacked");
    const gateway = this.Params.get("Gateway");

    if (threeDsMethodUrlServer) {
      this.rbsBrowserInfo(threeDsMethodUrlServer, threeDsMethodDataPacked, threeDsMethodUrl);
    } else if((gateway == Gateways.SberbankKz || gateway == Gateways.Jysan || gateway == Gateways.Rncb || gateway == Gateways.Connectum || gateway == Gateways.Bcc || gateway == Gateways.Ziraat) && threeDsMethodDataPacked && threeDsMethodUrl) {
      this.add3dsMethodDataPacket(threeDsMethodDataPacked, threeDsMethodUrl);
    } else if (threeDsMethodUrl && threeDsServerTransId) {
      this.defaultBrowserInfo(threeDsServerTransId, threeDsMethodUrl, transactionId);
    }

    Utils.postData<BaseResponse<SetBrowserInfoResponse>>(environment.api.host + environment.api.methods.processThreeDs, {
      ...this.getBrowserInfo(),
      ...{
        TransactionId: transactionId,
        ThreeDsCallbackId: threeDsCallbackId
      },
    }, `${transactionId}@${threeDsCallbackId}`).then((res) => {
      if (res.Model.ThreeDsRequired) {

        Utils.createAndSubmitForm(window.document, res.Model.AcsUrl!, this.createChallengeRequestByGatewayAndVersionThreeDs(res));

      } else {
        this.finalizePayment(res.Model.TermUrl, "ZnJpY3Rpb25sZXNz==", res.Model.MD)
      }
    }).catch(err => {
      this.finalizePayment(err.Model.TermUrl, "ZnJpY3Rpb25sZXNz==", err.Model.MD)
    });
  }

  private defaultBrowserInfo(threeDsServerTransId: string, threeDsMethodUrl: string, transactionId: number) {
    const threeDSMethodData = {
      threeDSMethodNotificationURL:
          environment.api.host + environment.api.methods.callback + "?TransactionId=" + transactionId,
      threeDSServerTransID: threeDsServerTransId,
    };
    try {
      this.acsFrame = this.createThreeDsMethodUrlFrame(
          threeDsMethodUrl,
          threeDSMethodData
      );

      document.body.appendChild(this.acsFrame);
    } catch(error) {
      console.log(error);
    }
  }

  private add3dsMethodDataPacket(threeDsMethodDataPacked: string, threeDsMethodUrl: string) {
    try {
      this.acsFrame = Utils.createIFrameWithForm(threeDsMethodUrl, {
        threeDSMethodData: Utils.removePadding(threeDsMethodDataPacked)
      });

      document.body.appendChild(this.acsFrame);
    } catch(error) {
      console.log(error);
    }
  }

  private rbsBrowserInfo(threeDsMethodUrlServer: string, threeDsMethodDataPacked: string | null, threeDsMethodUrl: string | null) {
    this.acsFrame = Utils.createIFrameWithForm(threeDsMethodUrlServer, {});
    document.body.appendChild(this.acsFrame);

    if (threeDsMethodDataPacked && threeDsMethodUrl) {
      this.acsDataPacketFrame = Utils.createIFrameWithForm(threeDsMethodUrl, {
        threeDSMethodData: threeDsMethodDataPacked
      });
      document.body.appendChild(this.acsDataPacketFrame);
    }
  }

  private finalizeChallengeTransaction() {
    const merchantTermUrl = this.Params.get("TermUrl")!;
    const cres = this.Params.get("CRes")!;
    const md = this.Params.get("MD")!;

    this.finalizePayment(merchantTermUrl, cres, md);
  }

  private finalizePayment(termUrl: string, pares: string, md: string) {
    Utils.createAndSubmitForm(window.document, termUrl, {
      PaRes: pares,
      MD: md
    });
  }

  private createThreeDsMethodUrlFrame(url: string, threeDsmethodData: any) {
    let base64threeDsmethodData = window.btoa(JSON.stringify(threeDsmethodData));

    base64threeDsmethodData = Utils.removePadding(base64threeDsmethodData.replaceAll("+", "-").replaceAll("/", "_"))!;

    const elem = document.createElement("iframe");

    elem.setAttribute("width", "0");
    elem.setAttribute("height", "0");
    elem.style.display = "none";

    elem.onload = () => {

      const iframeDocument = (elem as any).contentDocument || elem.contentWindow!.document;

      Utils.createAndSubmitForm(iframeDocument, url, {
        threeDSMethodData: base64threeDsmethodData
      });

      elem.onload = null;
    };

    return elem;
  }

  private createChallengeRequestByGatewayAndVersionThreeDs(setBrowserInfoResponse: BaseResponse<SetBrowserInfoResponse>) {

    const gateway = setBrowserInfoResponse.Model.Gateway;
    const threeDsVersion = setBrowserInfoResponse.Model.ThreeDsVersion;

    if(gateway == Gateways.Connectum)
    {
        if(threeDsVersion?.startsWith("1"))
        {
            return {
              PaReq: setBrowserInfoResponse.Model.CReq,
              MD: setBrowserInfoResponse.Model.MD,
              TermUrl: setBrowserInfoResponse.Model.TermUrl
            }
        }

        return {
          creq: Utils.removePadding(setBrowserInfoResponse.Model.CReq),
          threeDSSessionData: setBrowserInfoResponse.Model.ThreeDsSessionData
        }
    }

    if(gateway == Gateways.RSB)
    {
        if(threeDsVersion?.startsWith("1"))
        {
            return {
              PaReq: setBrowserInfoResponse.Model.CReq,
              MD: setBrowserInfoResponse.Model.MD,
              TermUrl: setBrowserInfoResponse.Model.TermUrl
            }
        }
    }

    if(gateway == Gateways.Rncb || gateway == Gateways.SberbankKz || gateway == Gateways.Jysan)
    {
        if(threeDsVersion?.startsWith("1"))
        {
          return {
            PaReq: setBrowserInfoResponse.Model.CReq,
            MD: setBrowserInfoResponse.Model.MD,
            TermUrl: setBrowserInfoResponse.Model.TermUrl
          }
        }

        return {
          creq: Utils.removePadding(setBrowserInfoResponse.Model.CReq),
          threeDSSessionData: setBrowserInfoResponse.Model.ThreeDsSessionData
        }
    }

    return {
      creq: Utils.removePadding(setBrowserInfoResponse.Model.CReq)
    }
  }

  private getBrowserInfo() {
    return {
      JavaEnabled: window.navigator.javaEnabled(),
      JavaScriptEnabled: true,
      Language: window.navigator.language,
      UserAgent: window.navigator.userAgent,
      TimeZone: new Date().getTimezoneOffset().toString(),
      Height: screen.height,
      Width: screen.width,
      ColorDepth: screen.colorDepth,
    };
  }
}

new Main();
